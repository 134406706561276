





































import { MutationPayload } from 'vuex';
import { defineComponent, reactive, ref } from '@vue/composition-api';
import Modal from '@/components/modal.vue';
import Input from '@/components/form/input.vue';
import router from '@/router';
import store from '@/store';
import { modal } from '@/components';
import { getRoles } from '@/api/auth';
import { fetchUserByEmail } from '@/api/users';
import useContext, { IUseContext } from '@/composition/context';
import useAuth from '@/composition/auth';
import { ILoginInputs, form, inputs } from './index';
// @ is an alias to /src

export default defineComponent({
  components: { Input, Modal },
  setup() {
    const { language, translations, userAdmin, relatedEvents } =
      useContext() as unknown as IUseContext;
    const { userAuthState, currentUser } = useAuth();

    const registerUser = false;

    const loginInputs = ref({} as ILoginInputs);

    const state = reactive({
      loading: false,
      userHasPassword: false,
    });

    const staging = process.env.VUE_APP_SERVER_PATH === 'https://b.beamian.com/api';

    const redirectUrl = process.env.VUE_APP_REDIRECT_URL.substring(
      0,
      process.env.VUE_APP_REDIRECT_URL.length - 2,
    );

    const message = ref('');

    const checkRoles = () => {
      if (userAdmin.value || relatedEvents.value.length > 1) {
        router.push('/events');
      } else if (relatedEvents.value.length === 1) {
        router.push(`/event/${relatedEvents.value[0].id}/roles`);
      } 
    };

    const loginUser = () => {
      state.loading = true;
      store.dispatch('loginUser', form);
      const unsubscribe = store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setUserLogin') {
          const userEmail = mutation.payload.user.email;
          getRoles().then((response) => {
            const v2 = response.data;
            store.commit('setRoles', v2);
            state.loading = false;

            if (!userAdmin.value) {
              fetchUserByEmail(userEmail).then(
                (response) => {
                  if (response.data.results.length === 1) {
                    const localStorageProp = process.env.VUE_APP_LOCAL_STORAGE_AUTH_PROP;
                    const user = response.data.results[0];
                    const auth = { ...mutation.payload, user };
                    localStorage.removeItem(localStorageProp);
                    localStorage.setItem(
                      localStorageProp,
                      JSON.stringify({
                        auth,
                        userLoggedIn: true,
                      }),
                    );
                    store.commit('setUserLogin', auth);
                    const redirect = localStorage.getItem(
                      process.env.VUE_APP_LOCAL_STORAGE_REDIRECT_PROP,
                    );
                    if (redirect && redirect !== '/login' && redirect !== '/') {
                      localStorage.removeItem(process.env.VUE_APP_LOCAL_STORAGE_REDIRECT_PROP);
                      router.push(redirect);
                    } else {
                      checkRoles();
                    }
                  } else {
                    // Could not get the user
                    store.commit('addPopup', {
                      message: 'User does not exist',
                      type: 'danger',
                      autohide: true,
                    });
                    // Logout
                    store.commit('setUserLogout');
                    router.push('/login');
                  }
                },
                (error) => {
                  // Something went wrong. Logout
                  console.log(error);
                  store.commit('setUserLogout');
                  router.push('/login');
                },
              );
            } else {
              const localStorageProp = process.env.VUE_APP_LOCAL_STORAGE_AUTH_PROP;
              const auth = mutation.payload;
              localStorage.setItem(
                localStorageProp,
                JSON.stringify({
                  auth,
                  userLoggedIn: true,
                }),
              );
              store.commit('setUserLogin', auth);
              const redirect = localStorage.getItem(
                process.env.VUE_APP_LOCAL_STORAGE_REDIRECT_PROP,
              );
              if (redirect && redirect !== '/login' && redirect !== '/') {
                localStorage.removeItem(process.env.VUE_APP_LOCAL_STORAGE_REDIRECT_PROP);
                router.push(redirect);
              } else {
                checkRoles();
              }
            }
          });
          unsubscribe();
        }

        if (mutation.type === 'setUserAuthError') {
          state.loading = false;
          if (userAuthState.value.message === 'wrong-password') {
            message.value = translations.value.auth.login.wrong_password;
          } else if (userAuthState.value.message === 'no-account') {
            message.value = translations.value.auth.login.wrong_password;
          }
        }
      });
    };

    if (currentUser.value.id) {
      const localStorageProp = process.env.VUE_APP_LOCAL_STORAGE_AUTH_PROP;
      localStorage.setItem(
        localStorageProp,
        JSON.stringify({
          user: currentUser,
          userLoggedIn: false,
        }),
      );

      store.commit('setUserLogout');
      store.commit('setContextRole', 'unset');
      store.commit('setContextEvent', {});
      store.commit('setContextExhibitor', {});
      store.commit('setContextVisitor', {});
    }

    loginInputs.value = inputs(translations.value);
    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setLanguage') {
        loginInputs.value = inputs(translations.value);
      }
    });
    return {
      loginUser,
      checkRoles,
      form,
      modal,
      registerUser,
      loginInputs,
      staging,
      redirectUrl,
      message,
      userAuthState,
      language,
      translations,
      state,
    };
  },
});
