











import Login from '@/partials/Login.vue';
import { defineComponent } from '@vue/composition-api';
// @ is an alias to /src

export default defineComponent({
  components: {
    Login,
  },
});
