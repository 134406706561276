import { IInput } from '@/components/form';
import { ITranslations } from '@/translations';

export interface IUserCard {
  picture?: string;
  name: string;
  detail?: string;
  description?: string;
  isCompact?: boolean;
  redirect?: string;
  route?: string;
}

export interface IExhibitorCard {
  name: string;
  logo?: string;
  subtitle?: string;
  description?: string;
  route?: string;
}

export interface IEventCard {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  image?: string;
  route: string;
}

export interface ILoginInputs {
  email: IInput;
  password: IInput;
}

export const form = {
  email: '',
  password: '',
};

export const inputs = (translations: ITranslations) => {
  const email = {
    id: 'user-email',
    name: 'email',
    label: translations.auth.login.email,
    type: 'text',
    icon: 'mail_outline',
    placeholder: translations.auth.login.email_placeholder,
    value: '',
  };

  const password = {
    id: 'user-password',
    name: 'password',
    label: translations.auth.login.password,
    type: 'password',
    icon: 'lock_open',
    placeholder: translations.auth.login.password_placeholder,
    value: '',
  };
  return {
    email, password,
  } as ILoginInputs;
};
